import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Helmet from '../components/helmet';
import Layout from '../layouts/default';
import Breadcrumb from '../component-elements/breadcrumb';
import ProductGrid from '../components/shop-category/product-grid';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import ContentHeaderCms from '../content-elements/contentHeaderCms';

const productsPerPage = parseInt(process.env.GATSBY_CATALOG_PRODUCTS_PER_PAGE, 10);

const BrandProductPages = ({
  pageContext,
  data: { datoCmsBrand: brand, datoCmsConfig: config },
  location,
}) => (
  <Layout textcolorHeader="black" textcolorHeaderMobile="black">
    <Breadcrumb
      items={[
        { id: brand.id, name: brand.name, url: brand.fullUrlPath },
        { id: 'brand_products_page', name: t`brand_products_page_title_suffix` },
      ]}
    />

    <ContentHeaderCms>
      <h1>{`${brand.name} ${t`brand_products_page_title_suffix`}`}</h1>
    </ContentHeaderCms>

    <ProductGrid
      staticFilters={[{
        key: 'jr_brand',
        values: [brand.magentoId],
      }]}
      location={location}
      lang={pageContext.lang}
      items={brand.magentoProducts}
      initialPage={pageContext.page}
      initialPageCount={Math.ceil(brand.magentoProductsCount / productsPerPage) || 1}
      initialItemTotal={brand.magentoProductsCount}
      breadcrumb={[
        { id: brand.id, name: brand.name, url: brand.fullUrlPath },
      ]}
    />

    {brand.productsPage?.content.map((content) => (
      <CmsMulticolumnBrand key={content.id} {...content} />
    ))}

    <Helmet
      tags={brand.productsPage?.seoMetaTags}
      metaRobots={brand.productsPage?.metaRobots}
      metaCanonical={brand.productsPage?.metaCanonical ? brand.productsPage?.metaCanonical : `${brand.fullUrlPath}${config.brandProductsPath}/`}
      title={brand.productsPage?.seo?.title}
      titleFallback={t('brand_products_seo_title_fallback', brand.name, brand.name)}
      description={brand.productsPage?.seo?.description}
      descriptionFallback={t('brand_products_seo_description_fallback', brand.name)}
    />
  </Layout>
);

BrandProductPages.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BrandProductPages;

export const query = graphql`
    query brandProductsQuery($brandId: String, $lang: String, $limit: Int, $skip: Int) {
        datoCmsBrand(id: {eq: $brandId}, locale: {eq: $lang}) {
            id
            name
            fullUrlPath
            isExternal
            magentoId
            magentoProductsCount
            magentoProducts(limit: $limit, skip: $skip) {
                ...shopProductListItem
            }
            productsPage: brandProducts {
                metaRobots
                metaCanonical
                seoMetaTags {
                    tags
                }
                seo {
                    title
                    description
                }
                content {
                    ...ce_multicolumn_text
                }
            }
        }
        datoCmsConfig {
            brandProductsPath
        }
    }
`;
